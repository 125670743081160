/**
 * @function
 * @name loadScript
 */
export default function loadScript (script) {
    return new Promise((resolve, reject) => {
        try {
            const el = document.createElement('script')

            // Processa as opções extras de carregamento de scripts
            const extras = []

            // async - o arquivo será executado assincronamente
            if (script.indexOf('async#') !== -1) {
                extras.push('async')
                script = script.replace('async#', '')
            }

            // defer - será executado no fim da página
            if (script.indexOf('defer#') !== -1) {
                extras.push('defer')
                script = script.replace('defer#', '')
            }

            if (script.indexOf('http://') === -1 && script.indexOf('https://') === -1) {
                // Busca o nome final do arquivo com o hash
                script = loadManifestName(script)
            }

            // Cria o elemento
            // el.setAttribute('id', '');
            el.setAttribute('type', 'text/javascript')
            el.setAttribute('src', script)

            if (extras.indexOf('defer') !== -1) {
                el.setAttribute('defer', 'defer')
            }

            if (extras.indexOf('async') !== -1) {
                el.setAttribute('async', 'true')
            }

            // Insere o elemento na página
            document.body.appendChild(el)

            if (extras.length > 1) {
                resolve(true)
            }

            el.onload = () => {
                resolve(true)
            }
        } catch (ex) {
            reject(ex)
        }
    })
}

/** @function */
function loadManifestName (script) {
    const ext = '.js' // TODO: permitir css
    
    // adiciona o domínio dos assets
    // eslint-disable-next-line no-undef
    script = `${window.assetsURL}/${script}`

    // eslint-disable-next-line no-undef
    if (CONSTANTS.sigla) {
        script = `${script}-${CONSTANTS.sigla}`
    }

    // adiciona o hash dos assets
    // eslint-disable-next-line no-undef
    if (CONSTANTS.hash) {
        // eslint-disable-next-line no-undef
        script = `${script}-${CONSTANTS.hash}`
    }

    return `${script}${ext}`
}
