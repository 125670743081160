/**
 * Retorna o domínio do site
 * @function
 * @name resolveDomain
 */
export default function resolveDomain () {
    let domain = 'gazetadopovo'

    // if (CONSTANTS.stage === 'loc') {
    //     const domains = CONSTANTS.domains
    //     const url = window.location.href
        
    //     for (const value of domains) {
    //         const regex = (new RegExp(`/${value}/`))
    //         if (regex.test(url)) {
    //             domain = value
    //         }
    //     }
    // } else {
    // console.log(CONSTANTS)
    // }
        
    domain = CONSTANTS.domain
    return domain
    // return domain
}
