import loadScripts from './libs/load-scripts'
import resolveDomain from './libs/resolve-domain'

(async () => {
    const domain = resolveDomain()
    console.log('abstracts', domain)
    try {
        // await loadScripts('backbone')
        await loadScripts(`async#${domain}-abstracts-core`)
        // eslint-disable-next-line no-undef
        const Core = window[CONSTANTS.coreName]

        await Core.start()

        Core.Tasks.add(['core'], [
            () => {
                // console.log('tarefa 01')
                // Configura a classe Config
                Core.Config.init()

                // Configura a classe console
                Core.Console.init()

                // Configura a classe Events
                Core.Events.init()

                // Configura a page
                Core.Page.init()
            },
            () => {
                // console.log('tarefa 02')
                Core.Modules.load('Lazyloading')
                Core.Modules.load('Visibility')
                Core.Modules.load('Alert')
                Core.Modules.load('Message')

                Core.Modules.get('Lazyloading').init()
                Core.Modules.get('Visibility').init()
            },
            `async#vendor-${domain}`,
            () => {
                // console.log('tarefa 03')
                // Carrega o Tema
                
                Core.Tasks.add([
                    `async#${domain}-_init`,
                    () => {
                        console.log('Tema iniciado')
                    }
                ])

                // Carrega os modulos abstratos de UI
                Core.Tasks.add(Core.Events.PAGE_READY, [
                    `async#${domain}-abstracts-ui-tools`,
                    () => {
                        console.log('Abstracts - UI-TOOLS')
                    }
                ])
            }])
    } catch (ex) {
        console.error(ex)
    }
})()
